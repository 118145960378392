"use client";

import { useTranslations } from "next-intl";
import Joyride, { Step } from "react-joyride";

export default function MainTour() {
  const t = useTranslations("tour");

  const steps: Step[] = [
    {
      target: "#new-patient-btn",
      content: t("patient.main.newPatientBtn"),
      locale: { close: t("closeBtn") },
    },
  ];

  const mainTour = localStorage.getItem("main-tour");
  if (mainTour === "done") return null;

  return (
    <Joyride
      steps={steps}
      disableOverlay={true}
      callback={(data) => {
        console.log("data", data);
        data.status === "finished" && localStorage.setItem("main-tour", "done");
      }}
    />
  );
}
