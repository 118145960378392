import { randomUUID } from "crypto";
import { NextResponse, type NextRequest } from "next/server";
import { S3 } from "aws-sdk";
import { z } from "zod";

import { db } from "@acme/db";
import { urlFromDbPhoto } from "@acme/utils";

import { env } from "~/env.mjs";

export const createUploadShape = z.object({
  signedUrl: z.string(),
  publicUrl: z.string(),
  bucketName: z.string(),
  key: z.string(),
  photoId: z.string(),
  uploadId: z.string(),
});

export async function createUpload(
  request: NextRequest,
  uploadData: { orgId: string; patientId?: string | undefined },
  currentUpload?: { id: string },
) {
  const objId = randomUUID();
  const bucketName = env.S3_BUCKET_NAME;

  const client = new S3({
    accessKeyId: env.S3_AWS_ACCESS_KEY_ID,
    secretAccessKey: env.S3_AWS_SECRET_ACCESS_KEY,
    region: "us-east-1",
  });

  // Get type from query string
  const url = new URL(request.url);
  const type = url.searchParams.get("fileType");

  console.log("type", type);

  const currentYear = new Date().getFullYear();

  const key = `${uploadData.orgId}/${currentYear}/${objId}`;

  /// Create a signed URL to a file readable by anyone
  const signedUrl = await client.getSignedUrlPromise("putObject", {
    Key: key,
    Bucket: bucketName,
    // Expires in 5 minute
    Expires: 60 * 5,
    ContentType: type || "image/jpeg",
    ACL: "public-read",
  });

  const publicUrl = urlFromDbPhoto({ bucket: bucketName, key });

  const photo = await db.photo.create({
    data: {
      bucket: env.S3_BUCKET_NAME,
      key,
      upload: currentUpload
        ? { connect: { id: currentUpload.id } }
        : { create: uploadData },
    },
  });

  const payload = {
    signedUrl,
    publicUrl,
    bucketName,
    key,
    photoId: photo.id,
    uploadId: photo.uploadId,
  } satisfies z.infer<typeof createUploadShape>;

  const ret = new NextResponse(JSON.stringify(payload));

  return ret;
}
//
